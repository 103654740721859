




import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({ components: {} })
export default class Search extends Vue {
  name = 'Home';

  mounted(): void {
    //@ts-ignore
    this.$gtag.pageview('/home');
  }
}
